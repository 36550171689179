import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { setJobTypeFilterValue } from "../features/sortSearchFilters/sortSearchFiltersSlice";

const Sidebar = () => {
  const dispatch = useDispatch();

  const handleJobType = (e) => {
    let targetSortValue =
      e.target.textContent === " All Available Jobs"
        ? ""
        : // remove starting and trailing spaces
          e.target.textContent.trim();

    dispatch(setJobTypeFilterValue(targetSortValue));
  };

  return (
    <div className="sidebar">
      <nav>
        <ul className="space-y-4">
          <li>
            <Link
              to="/"
              style={{ cursor: "pointer" }}
              className="main-menu menu-active"
              id="lws-alljobs-menu"
              onClick={handleJobType}
            >
              <i className="fa-solid fa-briefcase"></i>
              <span> All Available Jobs</span>
            </Link>
            <ul className="space-y-6 lg:space-y-2 ">
              <li>
                <div
                  style={{ cursor: "pointer" }}
                  className="sub-menu"
                  id="lws-internship-menu"
                  onClick={handleJobType}
                >
                  <i className="fa-solid fa-stop !text-[#FF5757]"></i>{" "}
                  Internship
                </div>
              </li>
              <li>
                <div
                  style={{ cursor: "pointer" }}
                  className="sub-menu"
                  id="lws-fulltime-menu"
                  onClick={handleJobType}
                >
                  <i className="fa-solid fa-stop !text-[#FF8A00]"></i> Full Time
                </div>
              </li>
              <li>
                <div
                  style={{ cursor: "pointer" }}
                  className="sub-menu"
                  id="lws-remote-menu"
                  onClick={handleJobType}
                >
                  <i className="fa-solid fa-stop !text-[#56E5C4]"></i> Remote
                </div>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/add" className="main-menu" id="lws-addJob-menu">
              <i className="fa-solid fa-file-circle-plus"></i>
              <span>Add NewJob</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
