import { instance } from "../../utils";

export const fetchJobsAPI = async () => {
    const response = await instance.get("/jobs");
    return response.data;
};

export const addJobAPI = (jobData) => {
    return instance.post("/jobs", jobData);
};

export const editJobAPI = async (jobData) => {
    const response = await instance.put(`/jobs/${jobData.id}`, jobData);
    return response.data;
};

export const deleteJobAPI = async (id) => {
    const response = await instance.delete(`/jobs/${id}`);
    return response.data;
};