import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    jobSalarySort: "",
    jobTypeFilter: "",
    searchQuery: "",
};

const sortSearchFiltersSlice = createSlice({
    name: "sortSearchFilters",
    initialState,
    reducers: {
        setJobSalarySortValue: (state, action) => {
            state.jobSalarySort = action.payload;
        },
        setJobTypeFilterValue: (state, action) => {
            state.jobTypeFilter = action.payload;
        },
        setSearchQueryValue: (state, action) => {
            state.searchQuery = action.payload;
        },
    },
});

export default sortSearchFiltersSlice.reducer;
export const { setJobSalarySortValue, setJobTypeFilterValue, setSearchQueryValue } = sortSearchFiltersSlice.actions;
