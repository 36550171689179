import { configureStore } from '@reduxjs/toolkit';

import jobsReducer from "../features/jobs/jobsSlice";
import sortFiltersReducer from "../features/sortSearchFilters/sortSearchFiltersSlice";

export const store = configureStore({
  reducer: {
    jobs: jobsReducer,
    sortFilters: sortFiltersReducer,
  },
});
