import React from "react";
import { useDispatch } from "react-redux";

import {
  setJobSalarySortValue,
  setSearchQueryValue,
} from "../features/sortSearchFilters/sortSearchFiltersSlice";

const Topbar = () => {
  const dispatch = useDispatch();

  const handleFilterType = (e) => {
    dispatch(setJobSalarySortValue(e.target.value));
  };

  const handleSearchQuery = (e) => {
    dispatch(setSearchQueryValue(e.target.value));
  };

  return (
    <div className="md:flex space-y-2 md:space-y-0 justify-between mb-10 ">
      <h1 className="lws-section-title">All Available Jobs</h1>
      <div className="flex gap-4">
        <div className="search-field group flex-1">
          <i className="fa-solid fa-magnifying-glass search-icon group-focus-within:text-blue-500"></i>
          <input
            type="text"
            placeholder="Search Job"
            className="search-input"
            id="lws-searchJob"
            onChange={handleSearchQuery}
          />
        </div>
        <select
          id="lws-sort"
          name="sort"
          autoComplete="sort"
          className="flex-1"
          onChange={handleFilterType}
        >
          <option value="">Default</option>
          <option value="salaryLtH">Salary (Low to High)</option>
          <option value="salaryHtL">Salary (High to Low)</option>
        </select>
      </div>
    </div>
  );
};

export default Topbar;
