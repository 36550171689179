import axios from "axios";

export const instance = axios.create({
    baseURL: "http://localhost:9000",
});

export const mapDBKeyWithFormName = (formName) => {
    if (formName === "lwsJobTitle") return "title";
    if (formName === "lwsJobType") return "type";
    if (formName === "lwsJobSalary") return "salary";
    if (formName === "lwsJobDeadline") return "deadline";
};