import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchJobs,
  sortJobs,
  filterJobs,
  searchJobs,
} from "../features/jobs/jobsSlice";
import JobItem from "./JobItem";

const JobList = () => {
  const dispatch = useDispatch();
  const { tempJobs, isLoading, isError, errorMessage } = useSelector(
    (state) => state.jobs
  );
  const { jobSalarySort, jobTypeFilter, searchQuery } = useSelector(
    (state) => state.sortFilters
  );

  useEffect(() => {
    dispatch(fetchJobs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(sortJobs(jobSalarySort));
  }, [dispatch, jobSalarySort]);

  useEffect(() => {
    dispatch(filterJobs(jobTypeFilter));
  }, [dispatch, jobTypeFilter]);

  useEffect(() => {
    dispatch(searchJobs(searchQuery));
  }, [dispatch, searchQuery]);

  return (
    <div className="jobs-list">
      {isLoading && <p>Loading...</p>}
      {isError ? (
        <strong>{errorMessage}</strong>
      ) : tempJobs.length > 0 ? (
        tempJobs.map((job) => <JobItem key={job.id} job={job} />)
      ) : (
        <strong style={{ color: "red" }}>No job found!</strong>
      )}
    </div>
  );
};

export default JobList;
