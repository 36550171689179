import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetchJobsAPI, addJobAPI, editJobAPI, deleteJobAPI } from "./jobsAPI";

const initialState = {
    jobs: [],
    tempJobs: [],
    isLoading: false,
    isError: false,
    errorMessage: ""
};

// async thunk
export const fetchJobs = createAsyncThunk(
    'jobs/fetchJobs',
    async () => {
        const response = await fetchJobsAPI();
        return response;
    }
);

export const addJob = createAsyncThunk(
    'jobs/addJob',
    async (payload) => {
        const response = await addJobAPI(payload);
        return response.data;
    }
);

export const editJob = createAsyncThunk(
    'jobs/editJob',
    async (payload) => {
        const response = await editJobAPI(payload);
        return response;
    }
);

export const deleteJob = createAsyncThunk(
    'jobs/deleteJob',
    async (payload) => {
        const response = await deleteJobAPI(payload.id);
        return response;
    }
);

const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        // filter by type
        filterJobs: (state, action) => {
            if (action.payload === "") state.tempJobs = state.jobs;
            else state.tempJobs = state.jobs.filter(job => job.type === action.payload);
        },

        // salaryLtH: salary low to high
        // salaryHtL: salary high to low
        sortJobs: (state, action) => {
            const mainJobs = state.tempJobs;
            if (action.payload === "") state.tempJobs = state.jobs;
            else
                state.tempJobs = mainJobs.sort((a, b) => {
                    if (action.payload === "salaryLtH") {
                        return a.salary - b.salary;
                    } else {
                        return b.salary - a.salary;
                    }
                });
        },

        // search by title
        searchJobs: (state, action) => {
            if (action.payload === "") state.tempJobs = state.jobs;
            else
                state.tempJobs = state.jobs.filter(job => {
                    return job.title.toLowerCase().includes(action.payload.toLowerCase());
                });
        }
    },
    extraReducers: (builder) => {
        builder
            // fetchJobs
            .addCase(fetchJobs.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.jobs = [];
                state.tempJobs = [];
                state.errorMessage = "";
            })
            .addCase(fetchJobs.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.errorMessage = "";
                state.jobs = action.payload;
                state.tempJobs = action.payload;
            })
            .addCase(fetchJobs.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.jobs = [];
                state.tempJobs = [];
                state.errorMessage = action.error?.message;
            })
            // addJob
            .addCase(addJob.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = "";
            })
            .addCase(addJob.fulfilled, (state) => {
                state.isLoading = false;
                state.isError = false;
                state.errorMessage = "";
            })
            .addCase(addJob.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.error?.message;
            })
            // editJob
            .addCase(editJob.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = "";
            })
            .addCase(editJob.fulfilled, (state) => {
                state.isLoading = false;
                state.isError = false;
                state.errorMessage = "";
            })
            .addCase(editJob.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.error?.message;
            })
            // deleteJob
            .addCase(deleteJob.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = "";
            })
            .addCase(deleteJob.fulfilled, (state, payload) => {
                state.isLoading = false;
                state.isError = false;
                state.errorMessage = "";
                state.jobs = state.jobs.filter(job => job.id !== payload.meta.arg.id);
                state.tempJobs = state.tempJobs.filter(job => job.id !== payload.meta.arg.id);
            })
            .addCase(deleteJob.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.error?.message;
            });

    }
});

export default jobsSlice.reducer;
export const { filterJobs, sortJobs, searchJobs } = jobsSlice.actions;
