import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './App.css';
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import AddJob from "./pages/AddJob";
import EditJob from "./pages/EditJob";
import Error404 from "./pages/Error404";
import Home from "./pages/Home";

function App() {
  return <Router>
    <Navbar />
    <Sidebar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/add" element={<AddJob />} />
      <Route path="/edit/:jobID" element={<EditJob />} />

      <Route path="*" element={<Error404 />} />
    </Routes>
  </Router>;
}

export default App;
